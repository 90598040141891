import React, { useState, useRef } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Row, Col } from 'react-bootstrap'
import { FaExclamationTriangle } from 'react-icons/fa'
import moment from 'moment'

import SelectedProperties from 'components/common/SelectedProperties'
import AddressRow from 'components/common/rows/AddressRow'
import FormLabel from 'components/common/forms/elements/FormLabel'
import FieldError from 'components/common/FieldError'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

import axios from 'utility/axios'
import * as logrocket from 'utility/logrocket'
import * as crisp from 'utility/crisp'
import colors from 'utility/colors'
import getNested from 'utility/getNested'
import { POST_SEARCH_PROPERTY_V3 } from 'utility/endpoints'
import * as google from 'utility/google'

const PropertyForm = ({
	errors,
	plan,
	selectedProperties = [],
	updateProperty,
	onAdd = () => console.log('Added property'),
	onRemove = () => console.log('Removed property'),
	inputUser = {},
	onClearProperties = () => console.log('Cleared Properties'),
	withLabels = true,
	labelColor,
	primaryColor,
	labels,
	promptText = 'Enter your home address to start...',
	inputStyle = {},
	height = 40,
	hideSignupBadge = false,
	hideEdit = false,
	hideSavings = false,
	hideOwnerDetails = false,
	hideOwnerCheck = false,
	hideBadges = false,
	hideDeadlineBadges = false,
	altLabels,
	searchContext,
	...props
}) => {
	const { data: tenantProfile } = useTenantProfile()

	const [foundProperties, setFoundProperties] = useState([])
	const [lastSearchDatetime, setLastSearchDateTime] = useState()
	// Get the user value and dispatch
	const [shouldMenuBeOpen, setShouldMenuBeOpen] = useState(false)

	// Ref for the Boostrap Typeahead
	const typeaheadEl = useRef(null)
	const [shouldConfirm, setShouldConfirm] = useState(false)
	// State hook to manage typeahead loading
	const [typeaheadLoading, setTypeaheadLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)

	/**
	 * Validate the form values before submission.
	 *
	 * @param {Object} values - an Object whose properties are all of the fields to validate
	 * @return {Object} error - an object with errors that need to be reported to the property names
	 */

	const search = async (query) => {
		logrocket.track('Searched Property')
		crisp.customEvent('Searched property')
		if (query && query.length && query.replace(' ', '').length >= 4) {
			setTypeaheadLoading(true)
			const _foundProperties = await searchAndFilterProperties(query, selectedProperties)
			setFoundProperties(_foundProperties)
			setTypeaheadLoading(false)
		}
	}

	const searchAndFilterProperties = async (searchText, selectedProperties) => {
		const newSearchText = searchText.trim()

		try {
			var searchContextId = moment().unix()
			setLastSearchDateTime(searchContextId)
			const response = await axios.post(POST_SEARCH_PROPERTY_V3, {
				contextId: searchContextId,
				tenantCode: tenantProfile?.code,
				numResults: 100,
				searchText: newSearchText,
				searchContext,
			})

			if (getNested(response, 'data', 'contextId') < lastSearchDatetime) {
				return null
			}

			const results = getNested(response, 'data', 'results')
			const filteredResponse =
				results &&
				results.length &&
				results.filter((property) => {
					for (let i = 0; i < selectedProperties.length; i += 1) {
						if (selectedProperties[i].id === property.searchId) {
							return false
						}
					}
					return true
				})
			return filteredResponse ? filteredResponse : []
		} catch (error) {
			console.error(error)
		}
	}

	const placeholder =
		selectedProperties && selectedProperties.length > 0
			? 'Add your rental properties too!'
			: promptText
	// If this form needs to be submitted then submit the button has been pressed outside form
	return (
		<>
			<Row>
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					{labels ? (
						labels
					) : withLabels ? (
						<>
							<FormLabel
								style={{
									color: labelColor ? labelColor : colors.GRAY,
								}}
								htmlFor='properties'
							>
								Property Search
							</FormLabel>
						</>
					) : null}
					<AsyncTypeahead
						id='properties'
						name='properties'
						promptText={promptText}
						emptyLabel={
							typeaheadLoading ? (
								<>Searching...</>
							) : (
								<div style={{ paddingTop: 20, paddingBottom: 20 }}>
									<FaExclamationTriangle size={16} style={{ marginRight: 10, marginTop: -10 }} />
									<p
										style={{
											fontSize: 18,
											display: 'inline-block',
											paddingBottom: 2,
										}}
									>
										Can't find your address? Chat with us or send us an email and let us know!
									</p>
								</div>
							)
						}
						ref={typeaheadEl}
						placeholder={placeholder}
						useCache={false}
						style={{
							height: height,
							borderRadius: 5,
						}}
						onSearch={search}
						onInputChange={(value) => {
							if (value && value.length) setShouldMenuBeOpen(true)
							else setShouldMenuBeOpen(false)
						}}
						isLoading={typeaheadLoading}
						onChange={async (selected) => {
							// This is where we add whether or not the property is the primary
							const selectedProperty = selected[0]
							if (selectedProperty) {
								onAdd(selectedProperty)
								setFoundProperties([])
								logrocket.track('Selected Property')
								crisp.customEvent('Selected Property')
								if (!selectedProperty.isSignedUp)
									google.event({
										action: 'unprotected_property_view',
										address: selectedProperty?.propertyAddress,
									})
							}
							onClearProperties()
							setShouldMenuBeOpen(false)
							setTimeout(() => {
								if (typeaheadEl && typeaheadEl.current) {
									typeaheadEl.current.clear()
									typeaheadEl.current.blur()
								}
							}, 0)
						}}
						options={foundProperties || []}
						filterBy={(option) => option}
						open={shouldMenuBeOpen}
						renderMenuItemChildren={(props, data, index) => {
							return (
								<AddressRow
									highlight={data.options && data.options.length === 1}
									county={props.countyName}
									propertyId={props.countyNumber}
									signedUp={!hideSignupBadge && props.isSignedUp}
									subtitle={props.ownerName}
									address={props.propertyAddress}
								/>
							)
						}}
						renderToken={(property) => <p>{property.propertyAddress}</p>}
						labelKey='propertyAddress'
						inputProps={{
							style: {
								width: '100%',
								height: height,
								borderRadius: 5,
								border: '1px solid #fff',
								boxShadow: '0 2px 20px 0 rgba(91, 91, 91, .1)',
								paddingLeft: 10,
								backgroundColor: 'white',
								...inputStyle,
							},
							type: 'text',
						}}
						clearButton
					/>
					{altLabels ? altLabels : null}
					{errors ? <FieldError>{errors}</FieldError> : null}
					{/* {labels ? labels : null} */}
				</Col>
			</Row>

			<SelectedProperties
				plan={plan}
				labelColor={labelColor}
				primaryColor={primaryColor}
				updateProperty={updateProperty}
				selectedProperties={selectedProperties}
				inputUser={inputUser}
				onRemoveProperty={(property) => onRemove(property)}
				hideEdit={hideEdit}
				hideSavings={hideSavings}
				hideOwnerCheck={hideOwnerCheck}
				hideOwnerDetails={hideOwnerDetails}
				hideBadges={hideBadges}
				hideDeadlineBadges={hideDeadlineBadges}
				style={{ marginTop: 10 }}
			/>
		</>
	)
}

export default PropertyForm
