import React from 'react'

import Text from 'components/common/Text'
import Card from 'components/common/cards/Card'
import colors from 'utility/colors'
import Icon from 'components/common/Icon'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

const HTSProtectedBlock = ({
	signedUp = true,
	dark,
	size = 'sm',
	link = false,
	containerStyle = {},
}) => {
	const { data: tenantProfile } = useTenantProfile()
	const tenantName = tenantProfile?.name
	const primaryColor = tenantProfile?.theme?.primaryColor
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	let backgroundColor = dark ? secondaryColor : colors.WHITE
	let protectedByTextColor = dark ? colors.WHITE : primaryColor
	let htsTextColor = dark ? colors.WHITE : secondaryColor

	let titleSize, protectedLineHeight, protectedSize, logoSize

	if (size === 'lg') {
		titleSize = 17
		protectedLineHeight = 16
		protectedSize = 14
		logoSize = 26
	} else if (size === 'xl') {
		titleSize = 17
		protectedLineHeight = 16
		protectedSize = 14
		logoSize = 32
	} else {
		titleSize = 14
		protectedLineHeight = 12
		protectedSize = 10
		logoSize = 20
	}

	return (
		<Card
			style={{
				opacity: signedUp ? 1 : 0.5,
				backgroundColor: backgroundColor,
				display: 'inline-block',
				width: 'fit-content',
				padding: '10px 10px',
				margin: 0,
				gap: 10,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				...containerStyle,
			}}
		>
			<span>
				<Text
					color={protectedByTextColor}
					size={protectedSize}
					style={{ padding: 0, margin: 0, lineHeight: `${protectedLineHeight}px` }}
				>
					{!signedUp ? 'Not ' : ''}Protected by
				</Text>
				<Text
					color={htsTextColor}
					size={titleSize}
					style={{ padding: 0, margin: 0, fontSize: titleSize, lineHeight: `${titleSize}px` }}
				>
					{tenantName}
				</Text>
			</span>
			<Icon icon='shield' size={20} color={htsTextColor} />
		</Card>
	)
}

export default HTSProtectedBlock
